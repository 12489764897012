@import './../../styles/abstracts/variables';
@import './../../styles/abstracts/mixins';

.footer {
    background-color: $header-background-color;
    margin: 3rem auto 0;
    padding: 3rem;
    display: block;
    font-size: 1.2rem;
    text-align: center;
    &__social {
        margin: 2rem auto;
        display: flex;
        justify-content: space-evenly;
        width: 20%;
        // @include respond(tab-port) {
        //     width: 20%;
        // }
        @include respond(phone) {
            width: 40%;
        }
        a {
            svg {
                width: 2.7rem;
            }
        }
    }
}