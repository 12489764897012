.btn {
    &,
    &:link,
    &:visited {
        color: $color-black;
        text-decoration: none;
        display: inline-block;
        @include respond(tab-port) {
            //padding: 1rem 2rem;
        }
    }
    &:focus {
        outline: none;
    }
    &:hover {
    }
    
    &:active {
    }
    &--effect {
        @include buttonEffect(3, 6, 4, $border-color-green);
    }
    &--simple {
        &:link,
        &:visited {
            border: 1px solid $border-color-green;
            background-color: $background-color-green;
            font-weight: 800;
        }
    }
    &--big {
        &:link,
        &:visited {
            padding: 1.5rem 3rem;
            border-radius: 3px;
        }
    }
    &--small {
        padding: .7rem 2rem;
        border-radius: 3px;
        border: none;
        font-size: 1.2rem;
        cursor: pointer;
    }
}
