@import './../../styles/abstracts/variables';
@import './../../styles/abstracts/mixins';

.categories-selection {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    margin-top: 4rem;
    &__item {
        margin-right: 3rem;
        margin-bottom: 2rem;
        padding: .2rem 1.5rem;
        background-color: #fff;
        border: 1px solid #000;
        border-radius: 1.3rem;
        box-shadow: 8px 8px;
        font-size: 2rem;
        cursor: pointer;
        &:not(.categories-selection__selected) {
            @include buttonEffect(5, 8, 0, #000);
        }
    }
    &__selected {
        background-color: #C6C6C6;
        box-shadow: 5px 5px;
        @include buttonEffect(3, 6, 0, #000);
    }
}