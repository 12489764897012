@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

html {
    scroll-behavior: smooth;
}
.home {
    display: block;
    &__top{
        padding: 10rem 15% 5rem 15%;
        background-color: $header-background-color;
        min-height: 90vh;
        @include respond(phone) {
            // padding: 10rem 5% 5rem 5%;
            // width: 100%;
        }
        @include respond(tab-port) {
            padding: 10rem 5% 5rem 5%;
            width: 100%;
        }
    }
    &__front {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        @include respond(phone) {
            align-items: top;
        }
    }

    &__title {
        width: 100%;
        @include respond(phone) {
            width: 60%;
        }
        &::after{
            // content: "";
            // display: block;
            // background-image: url('../../assets/parent\ hero\ image.svg');
            // background-position: center;
            // background-repeat: no-repeat;
            // background-size: 100%;
            // height: 30px;
            // width: 50px;
            // min-height: 50rem;
            // width: 50%;
        }
        h1 {
            font-family: 'Montserrat', sans-serif; 
            font-weight: 400; 
            font-style: normal;
            font-size: 4.2rem;
            letter-spacing: .1rem;
        }
    }

    &__image {
        background-image: url('../../assets/parent\ hero\ image.svg');
        background-repeat: no-repeat;
        background-size: contain;
        min-height: 30rem;
        background-position: center;
        width: 35%;
        @include respond(phone) {
            order: 1;
            width: 40%;
        }
    }

    .specifics {
        width: 60%;
        @include respond(phone) {
            order: 2;
            width: 90%;
        }
    }
    .single-specific {
        margin-top: 5rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        @include respond(phone) {
            margin-top: 2rem;
        }
        &__bulletpoint {
            margin: 1rem 3rem 0 auto;
            width: 1rem;
            box-sizing: border-box;
            &::after {
                content: "";
                display: block;
                padding: 100%;
                background-color: #fff;
                box-shadow: .3rem .3rem 3px;
                border: .3rem solid;
            }
        }
        &__paragraph {
            font-size: 2rem;
        }
    }
    .button {
        display: flex;
        justify-self: flex-end;
        margin-top: 4rem;
    }

    &__body {
        margin: 5rem auto;
        width: 70%;
        @include respond(phone) {
            width: 100%;
        }
        @include respond(tab-port) {
            width: 90%;
        }
    }
}