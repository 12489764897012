@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cutive&display=swap);
.latest-stuff-box{background-color:#fff;border:1px solid #06B100;padding:1.2rem;margin:5rem 0 3rem;width:30%;cursor:pointer;text-decoration:none}@media (max-width: 37.5em){.latest-stuff-box{width:100%;margin:2rem 0}}.latest-stuff-box__header{display:-webkit-flex;display:flex;-webkit-justify-content:space-between;justify-content:space-between;margin-bottom:2rem}.latest-stuff-box__date{font-size:1.2rem;text-transform:uppercase}.latest-stuff-box__categories{display:-webkit-flex;display:flex;-webkit-justify-content:space-between;justify-content:space-between}.latest-stuff-box__category{background-color:#cee5c7;border-bottom:4px solid #06B100;padding:0 0.5rem}.latest-stuff-box__category:not(last-child){margin-right:.5rem}.latest-stuff-box__title{margin-bottom:.8rem}.latest-stuff-box__description{font-size:1.4rem}.latest-stuff-box__more{background-image:url(/static/media/arrow-right.55347a09.svg);background-repeat:no-repeat;background-size:contain;height:2.4rem;width:5%;float:right;transition:-webkit-transform .3s;transition:transform .3s;transition:transform .3s, -webkit-transform .3s}.latest-stuff-box:hover .latest-stuff-box__more{-webkit-transform:scale(1.4);transform:scale(1.4)}

.latest-stuff-list{display:-webkit-flex;display:flex;-webkit-justify-content:space-between;justify-content:space-between;-webkit-align-items:center;align-items:center;-webkit-flex-wrap:wrap;flex-wrap:wrap}

html{scroll-behavior:smooth}.home{display:block}.home__top{padding:10rem 15% 5rem 15%;background-color:#FFFF74;min-height:90vh}@media (max-width: 56.25em){.home__top{padding:10rem 5% 5rem 5%;width:100%}}.home__front{display:-webkit-flex;display:flex;-webkit-justify-content:space-between;justify-content:space-between;-webkit-align-items:center;align-items:center;-webkit-flex-wrap:wrap;flex-wrap:wrap}@media (max-width: 37.5em){.home__front{-webkit-align-items:top;align-items:top}}.home__title{width:100%}@media (max-width: 37.5em){.home__title{width:60%}}.home__title h1{font-family:'Montserrat', sans-serif;font-weight:400;font-style:normal;font-size:4.2rem;letter-spacing:.1rem}.home__image{background-image:url("/static/media/parent hero image.1bd68f4d.svg");background-repeat:no-repeat;background-size:contain;min-height:30rem;background-position:center;width:35%}@media (max-width: 37.5em){.home__image{-webkit-order:1;order:1;width:40%}}.home .specifics{width:60%}@media (max-width: 37.5em){.home .specifics{-webkit-order:2;order:2;width:90%}}.home .single-specific{margin-top:5rem;display:-webkit-flex;display:flex;-webkit-justify-content:space-between;justify-content:space-between;-webkit-align-items:flex-start;align-items:flex-start}@media (max-width: 37.5em){.home .single-specific{margin-top:2rem}}.home .single-specific__bulletpoint{margin:1rem 3rem 0 auto;width:1rem;box-sizing:border-box}.home .single-specific__bulletpoint::after{content:"";display:block;padding:100%;background-color:#fff;box-shadow:.3rem .3rem 3px;border:.3rem solid}.home .single-specific__paragraph{font-size:2rem}.home .button{display:-webkit-flex;display:flex;justify-self:flex-end;margin-top:4rem}.home__body{margin:5rem auto;width:70%}@media (max-width: 37.5em){.home__body{width:100%}}@media (max-width: 56.25em){.home__body{width:90%}}

.about__main{margin-top:4rem;display:-webkit-flex;display:flex;line-height:2.5;-webkit-flex-wrap:wrap;flex-wrap:wrap}.about__img{background-image:url("https://res.cloudinary.com/diqh90gj2/image/upload/v1620225768/blog/id_qtvg2n.jpg");background-repeat:no-repeat;background-position:center;background-size:contain;border-radius:30%;height:2rem}

.contact__main{margin-top:4rem;display:-webkit-flex;display:flex;line-height:2.5;-webkit-flex-wrap:wrap;flex-wrap:wrap}.contact__items{list-style-type:none;display:-webkit-flex;display:flex;-webkit-justify-content:space-between;justify-content:space-between;-webkit-flex-flow:row;flex-flow:row;width:70%;margin-top:3rem}.contact__img{background-image:url("https://res.cloudinary.com/diqh90gj2/image/upload/v1620225768/blog/id_qtvg2n.jpg");background-repeat:no-repeat;background-position:center;background-size:contain;border-radius:30%;height:2rem}

.inline-code{background-color:#1b1f2312;padding-left:.4rem;padding-right:.4rem;border-radius:.22rem}

.article-page__categories{display:-webkit-flex;display:flex;-webkit-justify-content:flex-start;justify-content:flex-start;margin-top:3rem}.article-page__category{background-color:#cee5c7;border-bottom:4px solid #06B100;padding:0 0.5rem}.article-page__category:not(last-child){margin-right:.5rem}.article-page__content{margin-top:5rem;line-height:2;letter-spacing:1px}.article-page__content img{margin:4rem auto;height:auto;display:block;max-width:100%}.article-page__content pre{margin:3rem auto}.article-page__content ul{padding-left:4rem}.article-page__content h1{margin:3rem auto 2rem}

.categories-selection{display:-webkit-flex;display:flex;-webkit-justify-content:left;justify-content:left;-webkit-flex-wrap:wrap;flex-wrap:wrap;margin-top:4rem}.categories-selection__item{margin-right:3rem;margin-bottom:2rem;padding:.2rem 1.5rem;background-color:#fff;border:1px solid #000;border-radius:1.3rem;box-shadow:8px 8px;font-size:2rem;cursor:pointer}.categories-selection__item:not(.categories-selection__selected),.categories-selection__item:not(.categories-selection__selected):link,.categories-selection__item:not(.categories-selection__selected):visited{position:relative;top:0;box-shadow:5px 5px 0px #000;transition:all .2s ease-out}.categories-selection__item:not(.categories-selection__selected):focus,.categories-selection__item:not(.categories-selection__selected):active:focus{box-shadow:5px 5px 0px #000;top:0}.categories-selection__item:not(.categories-selection__selected):hover{box-shadow:8px 8px 0px #000;top:-3px}.categories-selection__selected{background-color:#C6C6C6;box-shadow:5px 5px}.categories-selection__selected,.categories-selection__selected:link,.categories-selection__selected:visited{position:relative;top:0;box-shadow:3px 3px 0px #000;transition:all .2s ease-out}.categories-selection__selected:focus,.categories-selection__selected:active:focus{box-shadow:3px 3px 0px #000;top:0}.categories-selection__selected:hover{box-shadow:6px 6px 0px #000;top:-3px}

.single-article-result{background-color:#fff;border:1px solid #06B100;padding:1.2rem;margin:1.5rem auto;cursor:pointer;text-decoration:none;width:100%}.single-article-result,.single-article-result:link,.single-article-result:visited{position:relative;top:0;box-shadow:3px 3px 0px #06B100;transition:all .2s ease-out}.single-article-result:focus,.single-article-result:active:focus{box-shadow:3px 3px 0px #06B100;top:0}.single-article-result:hover{box-shadow:6px 6px 0px #06B100;top:-3px}@media (max-width: 37.5em){.single-article-result{width:100%}}.single-article-result__date{font-size:1.2rem;text-transform:uppercase}.single-article-result__categories{display:-webkit-flex;display:flex;-webkit-justify-content:flex-start;justify-content:flex-start;margin-top:1rem}.single-article-result__category{background-color:#cee5c7;border-bottom:4px solid #06B100;font-size:1.4rem;padding:0 0.5rem}.single-article-result__category:not(last-child){margin-right:.5rem}

.articles-result{padding:1rem 0}

.articles{min-height:100vh}

.footer{background-color:#FFFF74;margin:3rem auto 0;padding:3rem;display:block;font-size:1.2rem;text-align:center}.footer__social{margin:2rem auto;display:-webkit-flex;display:flex;-webkit-justify-content:space-evenly;justify-content:space-evenly;width:20%}@media (max-width: 37.5em){.footer__social{width:40%}}.footer__social a svg{width:2.7rem}

.navigation{position:absolute;margin:3rem 25%}@media (max-width: 56.25em){.navigation{margin:3rem 5%}}.navigation--home{margin:3rem 15%}@media (max-width: 56.25em){.navigation--home{margin:3rem 5%}}.navigation__list{list-style-type:none;display:-webkit-flex;display:flex;-webkit-justify-content:space-around;justify-content:space-around;-webkit-flex-flow:row;flex-flow:row}.navigation__item{margin-right:4rem}@media (max-width: 56.25em){.navigation__item{margin-right:2rem}}.navigation__link--active{background-position:100%;box-shadow:3px 3px 0px #06B100}.navigation__link--effect,.navigation__link--effect:link,.navigation__link--effect:visited{position:relative;top:0;box-shadow:3px 3px 0px #06B100;transition:all .2s ease-out}.navigation__link--effect:focus,.navigation__link--effect:active:focus{box-shadow:3px 3px 0px #06B100;top:0}.navigation__link--effect:hover{box-shadow:6px 6px 0px #06B100;top:-3px}.navigation__link:link,.navigation__link:visited{font-weight:700;background-image:linear-gradient(120deg, transparent 0%, transparent 50%, #cee5c7 50%);background-color:#fff;background-size:220%;padding:.2rem 1rem .4rem;border:1px solid #06B100}.navigation__link:hover,.navigation__link:active{background-position:100%}

.opacity-0{opacity:0}body{font-family:'Lato', sans-serif;font-weight:400;font-size:1.6rem;line-height:1.7;color:#000}h1{font-family:'Cutive', sans-serif;font-weight:300;font-style:normal;font-size:3.5rem;letter-spacing:.5rem}h2{font-family:'Cutive', sans-serif;font-size:2rem;font-style:normal;font-weight:200}.box-title-text{font-family:'Cutive', sans-serif;font-size:1.8rem}.category-text{font-family:'Cutive', sans-serif;font-size:1.3rem}.article-title{font-size:4rem;font-weight:700}.btn,.btn:link,.btn:visited{color:#000;text-decoration:none;display:inline-block}.btn:focus{outline:none}.btn--effect,.btn--effect:link,.btn--effect:visited{position:relative;top:0;box-shadow:3px 3px 4px #06B100;transition:all .2s ease-out}.btn--effect:focus,.btn--effect:active:focus{box-shadow:3px 3px 4px #06B100;top:0}.btn--effect:hover{box-shadow:6px 6px 4px #06B100;top:-3px}.btn--simple:link,.btn--simple:visited{border:1px solid #06B100;background-color:#cee5c7;font-weight:800}.btn--big:link,.btn--big:visited{padding:1.5rem 3rem;border-radius:3px}.btn--small{padding:.7rem 2rem;border-radius:3px;border:none;font-size:1.2rem;cursor:pointer}.loading{min-height:50vh;text-align:center;font-weight:800;position:fixed}*,*::after,*::before{margin:0;padding:0;box-sizing:inherit}html{box-sizing:border-box;font-size:62.5%}@media (max-width: 75em){html{font-size:56.25%}}@media (max-width: 56.25em){html{font-size:50%}}@media (min-width: 112.5em){html{font-size:75%}}body{background-color:#ffc;line-height:1.6}@media (max-width: 56.25em){body{padding:0}}.page-header{padding:12rem 25% 7rem 25%;background-color:#FFFF74;display:block}@media (max-width: 37.5em){.page-header{padding:12rem 5% 7rem 5%}}@media (max-width: 56.25em){.page-header{padding:12rem 5% 7rem 5%}}.page-content{width:50%;margin-left:auto;margin-right:auto}@media (max-width: 37.5em){.page-content{width:100%}}@media (max-width: 56.25em){.page-content{width:90%}}

.App{margin:0 auto}.App__main{min-height:90vh}

