// MEDIA QUERY MANAGER
/*

0 - 600         phone
600 - 900:      Tablet portrait
900 - 1200:     Tablet landscape
1200 - 1800     where our normal styles apply

1800px + :      Big desktop

1em = 16px
*/

@mixin respond($breakpoint) {
    @if ($breakpoint == phone ) {
        @media (max-width: 37.5em) { @content }; // 600px
    }
    @if ($breakpoint == tab-port ) {
        @media (max-width: 56.25em) { @content }; // 900px
    }
    @if ($breakpoint == tab-land ) {
        @media (max-width: 75em) { @content }; // 1200px
    }
    @if ($breakpoint == small-desktop ) {
        @media (min-width: 75em) { @content }; // 1200px
    }
    @if ($breakpoint == big-desktop ) {
        @media (min-width: 112.5em) { @content }; // 1800px
    }
}

@mixin buttonEffect($border-width, $border-width-hover, $blur-radius, $border-color) {
    &,
    &:link,
    &:visited {
        position: relative;
        top: 0;
        box-shadow: $border-width+px $border-width+px $blur-radius+px $border-color;
        transition: all .2s ease-out;
    }
    &:focus, 
    &:active:focus {
        box-shadow: $border-width+px $border-width+px $blur-radius+px $border-color;
        top: 0;
    }
    &:hover {
        // border: 1px solid $border-color-green;
        box-shadow: $border-width-hover+px $border-width-hover+px $blur-radius+px $border-color;
        top: ($border-width - $border-width-hover)+px;
    }
}