@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cutive&display=swap');

body {
    font-family: 'Lato', sans-serif;;
    font-weight: 400;
    font-size: $default-font-size;
    line-height: 1.7;
    color: $color-black;
}

h1 {
    font-family: 'Cutive', sans-serif; 
    font-weight: 300; 
    font-style: normal;
    font-size: 3.5rem;
    letter-spacing: .5rem;
}

h2 {
    font-family: 'Cutive', sans-serif; 
    font-size: 2rem;
    font-style: normal;
    font-weight: 200; 
}
