@import './../../styles/abstracts/variables';
@import './../../styles/abstracts/mixins';

.navigation {
    position: absolute;
    margin:  3rem 25%;;
    // @include respond(phone) {
    //     margin:  3rem 5%;;
    // }
    @include respond(tab-port) {
        margin:  3rem 5%;;
    }
    &--home {
        margin: 3rem 15%;
        @include respond(tab-port) {
            margin:  3rem 5%;;
        }
    }
    &__list {
        list-style-type: none;
        display: flex;
        justify-content: space-around;
        flex-flow: row;
    }
    &__item {
        margin-right: 4rem;
        @include respond(tab-port) {
            margin-right: 2rem;
        }
    }
    &__link {
        &--active {
            background-position: 100%;
            box-shadow: 3px 3px 0px $border-color-green;
        }
        &--effect {
            @include buttonEffect(3, 6, 0, $border-color-green);
        }
        &:link,
        &:visited {
            font-weight: 700;
            background-image: linear-gradient(120deg, transparent 0%, transparent 50%, $background-color-green 50%);
            background-color: #fff;
            background-size: 220%;
            padding: .2rem 1rem .4rem;
            border: 1px solid $border-color-green;
        }
        &:hover,
        &:active {
            background-position: 100%;
        }
    }
}