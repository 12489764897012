*, 
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    font-size: 62.5%; // 1rem = 10px; 10px/16px= 62.5
    @include respond(tab-land) {
        font-size: 56.25%;             // 1rem = 9px, 9/16 = 56.25%
    }

    @include respond(tab-port) {
        font-size: 50%;             // 1rem = 8px, 8/16 = 50%
    } 

    @include respond(big-desktop) {
        font-size: 75%;                 // 1rem = 12px, 12/16 = 75%
    }
}

body {
    background-color: $background-color;
    line-height: 1.6;
    @include respond(tab-port) {
        padding: 0;     
    } 
}
