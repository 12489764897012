.contact {
    &__main {
        margin-top: 4rem;
        display: flex;
        line-height: 2.5;
        flex-wrap: wrap;
    }
    &__items {
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        flex-flow: row;
        width: 70%;
        margin-top: 3rem;
    }
    &__img {
        background-image: url('https://res.cloudinary.com/diqh90gj2/image/upload/v1620225768/blog/id_qtvg2n.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        border-radius: 30%;
        height: 2rem;
    }
}