@import './../abstracts/mixins';

.page-header {
    padding: 12rem 25% 7rem 25%;
    background-color: $header-background-color;
    display: block;
    @include respond(phone) {
        padding: 12rem 5% 7rem 5%;
    }
    @include respond(tab-port) {
        padding: 12rem 5% 7rem 5%;
    }
}
.page-content {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    @include respond(phone) {
        width: 100%;
    }
    @include respond(tab-port) {
        width: 90%;
    }
}
