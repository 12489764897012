@import './../../styles/abstracts/variables';
@import './../../styles/abstracts/mixins';

.single-article-result {
    background-color: #fff;
    border: 1px solid $border-color-green;
    padding: 1.2rem;
    margin: 1.5rem auto;
    cursor: pointer;
    text-decoration: none;
    width: 100%;
    @include buttonEffect(3, 6, 0, $border-color-green);
    @include respond(phone) {
        width: 100%;
    }
    &__date {
        font-size: 1.2rem;
        text-transform: uppercase;
    }
    &__categories {
        display: flex;
        justify-content: flex-start;
        margin-top: 1rem;
    }
    &__category {
        &:not(last-child){
            margin-right: .5rem;
        }
        background-color: $background-color-green;
        border-bottom: 4px solid $border-color-green;
        font-size: 1.4rem;
        padding: 0 0.5rem;
    }
}