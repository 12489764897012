@import '../../styles/abstracts/variables';

.article-page {
    &__header {
    }
    &__categories {
        display: flex;
        justify-content: flex-start;
        margin-top: 3rem;
    }
    &__category {
        &:not(last-child){
            margin-right: .5rem;
        }
        background-color: $background-color-green;
        border-bottom: 4px solid $border-color-green;
        padding: 0 0.5rem;
    }
    &__content {
        margin-top: 5rem;
        line-height: 2;
        letter-spacing: 1px;    
        img {
            margin: 4rem auto;
            height: auto;
            display: block;
            max-width: 100%;
        }

        pre {
            margin: 3rem auto;
        }
        ul {
            padding-left: 4rem;
        }
        h1 {
            margin: 3rem auto 2rem;
        }
    }
}