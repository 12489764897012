@import './../../styles/abstracts/variables';
@import '../../styles/abstracts/mixins';

.latest-stuff-box {
    background-color: #fff;
    border: 1px solid $border-color-green;
    padding: 1.2rem;
    margin: 5rem 0 3rem;
    width: 30%;
    cursor: pointer;
    text-decoration: none;
    @include respond(phone) {
        width: 100%;
        margin: 2rem 0;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;
    }
    &__date {
        font-size: 1.2rem;
        text-transform: uppercase;
    }
    &__categories {
        display: flex;
        justify-content: space-between;
    }
    &__category {
        &:not(last-child){
            margin-right: .5rem;
        }
        background-color: $background-color-green;
        border-bottom: 4px solid $border-color-green;
        padding: 0 0.5rem;
    }
    &__title {
        margin-bottom: .8rem;
    }
    &__description {
        font-size: 1.4rem;
    }
    &__more{
        background-image: url('./../../assets/arrow-right.svg');
        background-repeat: no-repeat;
        background-size: contain;
        height: 2.4rem;
        width: 5%;
        float: right;
        transition: transform .3s;
    }
    // &:hover {
    //     // border: 1px solid $border-color-green;
    //     box-shadow: 6px 6px 4px $border-color-green;
    //     top: -4px;
    // }
    &:hover &__more{
        transform: scale(1.4);
    }
    // &:hover &__title,     
    // &:hover &__description,
    // &:hover &__date,
    // &:hover &__category{
    //     transform: scale(.9);
    // }
}