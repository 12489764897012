$header-background-color: #FFFF74;
$background-color: #FFFFCC;

$background-color-white: #fff;
$border-color-green:#06B100;
$background-color-green:#cee5c7;

$background-color-dark:#C6C6C6;
$color-form-red: #b80000;
$color-form-green: #55c57a;

$color-light-grey: #cfcfcf;
$color-black: #000;
$color-white: #fff;

// FONT 
$default-font-size: 1.6rem;
