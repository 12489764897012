.box-title-text {
    font-family: 'Cutive', sans-serif; 
    font-size: 1.8rem;
}

.category-text {
    font-family: 'Cutive', sans-serif; 
    font-size: 1.3rem;
}

.article-title {
    font-size: 4rem;
    font-weight: 700;
}